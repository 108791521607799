import React from 'react'
// import user from './../vocational/userInfo'

class DDClient {
    constructor() {
        window.g = {
            share_data: {},
            share: function (json) {
                json = JSON.stringify(json);
                if( typeof client != 'undefined' ){
                    window.client.share(json);
                    return;
                }else if( typeof webkit != 'undefined' ){
                    try {
                        window.webkit.messageHandlers.client_share.postMessage(json);
                    }
                    catch(err) {
                        window.webkit.messageHandlers.share.postMessage(json);
                    }
                    return;
                }else{
                    return json;
                }
            }
        }
        window.app = {
            share_callback: function (code,type,msg) {
                return 1;
            },
            share: function() {
                return window.g.share(window.g.share_data);
            }
        }
    }

    appName = '叮叮抓娃娃'

    share({title, content, picurl, linkurl}) {
        window.g.share_data = {
            title,
            content,
            picurl,
            linkurl,
            sharelist: ['weixin','weixin_friend','qzone', 'sina']
        }
        // if (user.isAndroid) {
        //     }
        // } else {
        // }
        window.app.share()
    }
}

/**
 * 客户端方法
 */
export const client = {
    // 叮叮
    dd: new DDClient(),
    // 乐萌
    lemeng: {
        selectBg: '#FDF186'
    },
    // 部落
    leyuan: {
        selectBg: '#FF4337'
    },
    // 欢聚
    hj: {
        selectBg: '#FDF186'
    }
}

export const ClientContext = React.createContext(
	client.dd // 默认值
);
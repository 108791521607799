import React from 'react'

export const theme = {
    // 叮叮
    dd: {
        selectBg: '#FDF186'
    },
    // 乐萌
    lemeng: {
        selectBg: '#FDF186'
    },
    // 部落
    leyuan: {
        selectBg: '#FF4337'
    },
    // 欢聚
    hj: {
        selectBg: '#FDF186'
    }
}

export const ThemeContext = React.createContext(
	theme.dd // 默认值
);
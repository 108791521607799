/**
 * 基础类 —— 接口基础信息以及调用
 */
import { version, appName, os, isDev, baseURL } from '../../base.js' // 基本信息文件
import { requestNum } from '../../utils/common.js';
import user from './../../vocational/userInfo'
import httpResult from './../base/httpResult.js'
import { Toast } from 'antd-mobile';
import axios from 'axios'

export default class ServerConstruction {
	constructor() {
		axios.defaults.baseURL = this.baseURL
	}
	// 环境
	static env = 'dev' // 'prod'
	static baseURL = baseURL
	static timeDifference = null  // 与服务器的时间差
	static ip = null // IP地址

	get baseURL() {
		if (isDev) {
			return '/'
		} else {
			return ServerConstruction.baseURL
		}
	}

	/**
	 * ----------  start 开始调用接口 ------------
	 * @param {string} name 服务器接口名称
	 * @param {boolean} test 表示接口还在测试阶段，默认返回 construction 里面的数据，用于 mock 数据
	 * @param {Array} mustParam 必传参数
	 * @param {Function} construction 适配方法
	 */
	start(serverName, param = null) {
		return new Promise(async (resolve, reject) => {
			let httpData = null // 服务器数据
			let paramOk = true // 参数正常
			let serverInfo = ''
			try{
				serverInfo = this[serverName]()
			} catch (e) {
				console.error(`找不到接口 ${serverName} 的配置信息`)
				Toast.hide()
				reject({
					code: -1,
					data: null,
					msg: `找不到接口 ${serverName} 的配置信息`
				})
			}
			let noSetParam = serverInfo.mustParam // 保存缺少的必传参数

			// 判断必传参数
			if (!!serverInfo.mustParam) {
				if (param !== null) {
					const paramKey = Object.keys(param)
					if (paramKey.length === 0 && serverInfo.mustParam.length !== 0) paramOk = false
					else {
						noSetParam = serverInfo.mustParam.filter((item, index) => {
							return paramKey.indexOf(item) === -1
						})
						if (noSetParam.length !== 0) paramOk = false
					}
				} else paramOk = false
			} else paramOk = true
			// 调用服务器接口
			if (paramOk) {
				if (serverInfo.test) {
					// 测试接口，直接返回 construction 中的数据
					resolve(serverInfo.construction())
				} else {
					// 检测URL是否携带参数，如果携带参数，则传参去掉该参数
					let url = serverInfo.name
					if (!!serverInfo.isUrlParam && serverInfo.isUrlParam.length !== 0) {
						serverInfo.isUrlParam.forEach(item => {
							let exp = new RegExp(item, 'g')
							url = url.replace(exp, param[item])
							delete param[item]
						})
					}
					// 获取 Header
					try{
						const header = await this.setHeader(serverInfo.isLogin)
						try {
							const requestGetData = {
								header,
								url,
								options: param === null ? {} : param,
								method: !!serverInfo.method ? serverInfo.method : 'get'
							}
							httpData = await this.requestGet(requestGetData)
							// 不传 construction 则不对数据做任何处理
							resolve(!!serverInfo.construction ? serverInfo.construction(httpData, param === null ? {} : param) : httpData)
						} catch(e) {
							console.error(`HTTP返回异常: `, e)
							// 抛出异常
							reject(e)
							// errorToast = false 则不打印
							if (serverInfo.errorToast !== false) {
								Toast.hide()
								if (e.code !== 302 && e.msg !== 'SessionId错误') {
									Toast.info(e.msg !== undefined ? e.msg : '手速太快，请再试一次', 2, null, false)
								}
							}
						}
					} catch(e) {
						console.error(`用户未登录，无法调用接口 ${serverName}, 请检查登录信息，或者把 isLogin 配置为 false`)
						reject({
							code: 302,
							data: e,
							msg: `用户未登录，无法调用接口 ${serverName}, 请检查登录信息，或者把 isLogin 配置为 false`
						})
						Toast.hide()
						httpResult({
							code: 302,
							data: e,
							msg: `用户未登录，无法调用接口 ${serverName}, 请检查登录信息，或者把 isLogin 配置为 false`
						})
					}
				}
			} else {
				Toast.hide()
				console.error(`接口 ${serverName} 缺少必传参数 ${noSetParam.join(', ')}, 请先检查参数正确`)
				reject({
					code: -1,
					data: null,
					msg: `接口 ${serverName} 缺少必传参数 ${noSetParam.join(', ')}, 请先检查参数正确`
				})
			}
		})
	}
	
	/**
	 * 处理 Header 数据 
	 */
	// requestId 自增数字
	httpNum = 0
	setHeader(isLogin) {
		return new Promise(async (resolve, reject) => {
			// 设置 header 头部信息
			let header = {
				'requestId': `${requestNum()}-${this.httpNum}`,
				'downFrom': user.downFrom,
				'os': os,
				'version': version,
				'appName': appName,
				'imei': ServerConstruction.ip,
				'requestTime': this.beijingTime
			}
			this.httpNum++
			// 判断接口是否需要登录才可请求
			// 这个项目不需要判断登陆
			// if (isLogin !== false) {
			// 	// 说明需要进行登录才可以请求
			// 	if (!!user.info.sessionId) {
			// 		header.sessionId = user.info.sessionId
			// 		header.userId = user.info.userId
			// 	} else {
			// 		// 如果没有 sessionId ，说明调用接口会有问题
			// 		reject(null)
			// 		return null
			// 	}
			// } else {
			// 	// 无需登录请求，直接跳过 sessionId 与 userId
			// 	// 特殊时期,先留着，正式需要去掉这个，不然会有BUG
			// 	if (!!user.info.sessionId) {
			// 		header.sessionId = user.info.sessionId
			// 		header.userId = user.info.userId
			// 	}
			// }
			// header 去掉空的字段
			Object.keys(header).forEach(key => {
				if (header[key] === '' || header[key] === undefined || header[key] === null) {
					delete header[key]
				}
			})
			header.contentType = 'application/json'
			resolve(header)
		})
	}
	
	/*
	 * @param {String} name      接口名称
	 * @param {Object} options   参数
	 */
	async requestGet({
		header,
		method = 'get',
		url,
		options = {}
	}) {
		// options 去掉空的字段
		Object.keys(options).forEach(key => {
			// options[key] === '' || 
			if (options[key] === undefined || options[key] === null) {
				console.debug(`调用接口 ${url} 时， ${key} 的值为 undefined || null ，已经被程序自动过滤`)
				delete options[key]
			}
		})

		return new Promise((resolve, reject) => {
			axios({
				method,
				url,
				params: method === 'get' ? options : null,
				data: method === 'post' ? options : null,
			}).then((res) => {
				const result = httpResult(res.data)
				if (result) {
					resolve(res.data.data)
				} else {
					reject(res.data)
				}
			}).catch(error => {
				reject(error)
			})
		})
	}

	// 返回北京时间的时间戳
	get beijingTime() {
		return new Date().getTime() + +ServerConstruction.timeDifference
	}
}
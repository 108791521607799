import React, { Suspense } from 'react'
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import CacheRoute, { CacheSwitch } from 'react-router-cache-route'
// 路由JSON
import { routerList } from './router'
// 获取链接参数
import { getQueryString } from './utils/common'
// 皮肤
import { ThemeContext, theme } from './skin/skin'
import { ClientContext, client } from './utils/client'
// 全局组件
import GlobalComponent from './global/main';

// 程序主入口
export default class App extends React.Component {
	//动态插入meta标签
	UNSAFE_componentWillMount(){
		let oMeta = document.getElementsByTagName('meta');
		let hasViewPort = false;
		for (let i = 0; i < oMeta.length; i++) {
			const name = oMeta[i].name;
			if(name === 'viewport'){
				hasViewPort = true;
				oMeta[i].content = 'width=device-width,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no,viewport-fit=cover'
			}
		}

		if(!hasViewPort){
			const headItem = document.head;
			const viewPortMeta = document.createElement('meta');
			viewPortMeta.setAttribute('name','viewport');
			viewPortMeta.content = 'width=device-width,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no,viewport-fit=cover';
      		headItem.appendChild(viewPortMeta);
		}
	}
	render() {
		const appName = getQueryString('app') || 'dd'
		return (
			<Router>
				<ClientContext.Provider value={ client[appName] }> {/* 在此处修改不同平台的APP方法 */ }
					<ThemeContext.Provider value={ theme[appName] }> {/* 在此处修改主题 */ }
						<Route path="/">
							<GlobalComponent name="GlobalComponent"></GlobalComponent>
							<Suspense fallback={<div className="quanju-loading"></div>}>
								<KeepAlive router={routerList}></KeepAlive>
								<RoutineRoute router={routerList}></RoutineRoute>
							</Suspense>
						</Route>
					</ThemeContext.Provider>
				</ClientContext.Provider>
			</Router>
		)
	}
}

function KeepAlive({ router }) {
	return (
		<CacheSwitch>
			{router.map(route => {
				if (!!route.keepAlive) {
					return <CacheRoute 
						key={ route.name }
						exact
						path={ route.path }
						render={(props) => {
							document.title = route.title
							return (
								<route.component {...props}></route.component>
							)
						}}
						>
					</CacheRoute>
				} else return null
			})}
			<Redirect exact from="/" to="/home" />
		</CacheSwitch>
	)
}

function RoutineRoute({ router }) {
	if(router)
	return (
		<Switch>
			{router.map(route => {
				if (!route.keepAlive) {
					return <Route 
						key={ route.name }
						exact
						path={ route.path }
						render={(props) => {
							document.title = route.title
							return (
								<route.component {...props}></route.component>
							)
						}}>
					</Route>
				} else return null
			})}
			<Redirect exact from="/" to="/home" />
		</Switch>
	)
}

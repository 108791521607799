import server from './../server/mall'
import Vocational from './vocational'
import { isDev } from './../base'

/**-----------------------------
 *********   用户信息模块   *********
 *-----------------------------*/
class UserVocational extends Vocational {

    static u = navigator.userAgent

    async getUser() {
        if (isDev) {
            // 测试
            this.info = {
                "id":"20005276",
                "avatar":"http://thirdwx.qlogo.cn/mmopen/vi_32/PdXJV52q6CKmJouWn6mwWSdUI4gSbsxY6LGJUh4FsV0hOE5CCyZJ6VVfJib45cmJERCvCE8wudK9q6MqxT3JynQ/132",
                "nick":"5aWL5paX",
                "amount":"0",
                "invite_code":null,
                "invite_picture":null,
                "invite_picture_2":null,
                "create_time":"1551270885",
                "duimian_id":null,
                "third_type":"weixin",
                "third_token":"oCkLDjoKJeakufnS7e1-Z4ioqDuE",
                "phone":null,
                "vip_id":"0",
                "score":"140",
                "imei":null
            }
        } else {
            let search = window.location.search  // 拿到地址栏的所有查询参数
			search = search.slice(1, search.length)  // 截取查询字符串的第一个?
            // console.log('____searchUrl: ', search)
            let route = window.location.hash.substr(1) // 获取当前页的路由
            // console.log('____route: ', route)

            let paramsObj = {}
            let paramsArr = search.split("&")
            // 拆解地址栏参数
            paramsArr.forEach(e => {
                var result = e.split("=")
                paramsObj[result[0]] = result[1]
            })
            paramsObj.route = route
            console.log('__paramsObj: ', paramsObj)
            
            this.info = await server.start('getUser', paramsObj)
        }   
        // console.log('this.info: ', this.info)
        // console.log('UserVocational.u: ',UserVocational.u)
    }
    isAndroid = UserVocational.u.indexOf('Android') > -1 || UserVocational.u.indexOf('Adr') > -1 //android终端
    isAPP = !(UserVocational.u.indexOf("dingdingwawaji") === -1 && UserVocational.u.indexOf("dd_lite") === -1) // 是否为客户端 true是
    isIOSLite = !this.isAndroid && UserVocational.u.indexOf("dd_lite") !== -1 // 是否是IOS畅玩版 true是
    isIOS = !this.isAndroid && !!UserVocational.u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // 是否IOS终端 true是
}

const user = new UserVocational({
    info: null,
    downFrom: '',
    goRoute: null,
})

export default user
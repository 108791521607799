/**
 * 基础类 —— 全局异常策略
 */
// import { pageJump } from './../../utils/common';
// import login from '@/vocational/loginVocational.js';

export default (res) => {
	if (!!res.status && res.status === 404) {
		return false
	}
	return !!disposeFun[res.code] ? disposeFun[res.code](res) : disposeFun.other(res)
}

const disposeFun = {
	200: (data) => {
		return true
	},
	301: (data) => {
		// 用户未绑定手机号码
		return false
	},
	302: (data) => {
		// 用户未登录
		// login.showLoginModal = true
		// login.logout()
		console.log('302eeeee: ',data)
		console.log('____302跳转: ', data.data)
		window.location.href = data.data
		return false
	},
	403: (data) => {
		// 请求禁止
		return false
	},
	405: (data) => {
		// 解码失败, 登出
		// login.logout()
		return false
	},
	500: (data) => {
		// 服务器错误
		return false
	},
	other: (data) => {
		// 其他情况
		return false
	}
}
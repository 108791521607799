/**
 * 基础类 —— 数据绑定
 */
export default class Vocational {
	/**
	 * 绑定数据函数
	 */
	bindParame = {}
	
	constructor(parame) {
		const _this = this
		// name 属性只读
		Object.defineProperty(this.bindParame, 'name', {
			get () {
				return function (name, obj, execute = true) {
					for (let key in obj) {
						if (this[name] === undefined) this[name] = {}
						this[name][key] = obj[key]
						// execute = true : 默认会直接执行一次, 传入false的话, 变量不变化, 就不会执行
						if (_this[key] !== null) {
							if (execute) {
								obj[key] && obj[key](_this[key])
							}
						}
						
					}
				}
			}
		})
		// 初始化参数双向绑定
		for (let key in parame) {
			this[`#${key}`] = parame[key]
			Object.defineProperty(this, key, {
				get: () => {
					return this[`#${key}`]
				},
				set: (val) => {
					if (val !== this[`#${key}`]) {
						this[`#${key}`] = val
						if (val !== null) {
							for (let name in this.bindParame) {
								if (name !== 'name') {
									this.bindParame[name][key] && this.bindParame[name][key](val)
								}
							}
						}
					}
				}
			})
		}
	}
	
	/**
	 * 动态添加数据回调模式
	 * 作用: 
	 * 1. 动态添加数据回调（可以不用写在初始化工厂函数的地方)
	 * 2. 添加额外的事件回调, 如果传进fn, 则会在原来调用 this.bindParame[name][key] 的基础上,每次额外调用该 fn
	 * @param {string} key 要绑定的参数
	 * @param {any\} val 初始值
	 * @param {Function} fn 除了默认的 bindParame 需要额外添加的 回调函数
	 */
	dynamicBind(key, val, fn) {
		if (typeof this[`#${key}`] !== "undefined") {
			console.error(`注意: 参数${key} 已经初始化过了, 目前同一个函数只能初始化一次!`)
		} else {
			this[`#${key}`] = val
			Object.defineProperty(this, key, {
				get: () => {
					return this[`#${key}`]
				},
				set: (val) => {
					if (val !== this[`#${key}`]) {
						this[`#${key}`] = val
						if (val !== null) {
							for (let name in this.bindParame) {
								if (name !== 'name') {
									this.bindParame[name][key] && this.bindParame[name][key](val)
								}
							}
							fn(val)
						}
					}
				}
			})
		}
	}
	
	/**
	 * @description 清理数据绑定
	 * @param {String} name 命名空间
	 * @param {String} key 要清理的发布事件的 key , 传入一个 string, 多个使用 ',' 分割
	 */
	clearIssue(name, keys) {
		const key = keys.split(',')
		if (key.length > 0) {
			key.forEach(item => {
				if (!!this.bindParame[name]) {
					delete this.bindParame[name][item]
				}
			})
		}
	}
}
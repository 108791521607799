import { formatImg, requestNum } from './../utils/common'
import ServerConstruction from './base/serverConstruction.js'
import bannerImg from './../assets/banner.jpg'
/**
 * 消息接口文件
 */

class Server extends ServerConstruction {
	/**
	 * 获取用户信息
	 */
	getUser() {
		return {
			name: `index/user`,
			isLogin: false,
			errorToast: false,
		}
	}
	/**
	 * 1、商城首页
	 */
	mallIndex() {
		return {
			name: `client_index/index`,
			isLogin: false,
			construction: function(data) {
				if (!data.banner) data.banner = [{
					ad: bannerImg,
					id: requestNum(),
					link: '',
				}]
				else {
					// 活动banner
					data.banner = data.banner.slice(0, 5).map(item => {
						item.ad = formatImg(item.ad, 702, 280)
						item.id = requestNum()
						return item
					})
				}
				if (!data.icon) data.icon = []
				else {
					// 活动icon
					if (data.icon.length >= 5 && data.icon.length < 10) data.icon = data.icon.slice(0, 5)
					else if (data.icon.length >= 10) data.icon = data.icon.slice(0, 10)
					else data.icon = []
					// 活动icon
					data.icon = data.icon.map(item => {
						item.ad = formatImg(item.ad, 72, 72)
						item.id = requestNum()
						return item
					})
				}
				if (!data.module) data.module = []
				else {
					// 活动模块
					if (data.module.length >= 2 && data.module.length < 4) data.module = data.module.slice(0, 2)
					else if (data.module.length >= 4) data.module = data.module.slice(0, 4)
					else data.module = []
					data.module = data.module.map(item => {
						item.ad = item.ad.split(',').map(img => formatImg(img))
						// item.ad = item.ad.split(',')[0]
						item.base_map = formatImg(item.base_map)
						item.seckillId = requestNum()
						return item
					})
				}
				if (!data.theme) data.theme = []
				else{
					data.theme = data.theme.map(item => {
						item.goods_list.map(good => {
							// good.images = good.images.split(',').map(img => formatImg(img))
							good.images = formatImg(good.images.split(',')[0])
							return null;
						})
						return item;
					})
				}
				data.product_type = data.product_type.map(item => {
					item.title = item.name
					return item
				})
				data.cart_num = +data.cart_num > 99 ? '99+' : data.cart_num
				return data
			}
		}
	}
	
	/**
	 * 2、商城列表
	 * @param {number} id  商品分类ID -1为推荐分类 -2为所有商品
	 */
	mallProductList() {
		return {
			name: `client_product/list`,
			isLogin: false,
			mustParam: ['id'],
			construction: function(data, param) {
				// if (+param.id === -1) {
				// 	data = data.map(item => {
				// 		item.images = formatImg(item.images.split(',')[0])
				// 		item.sales = +item.sales
				// 		item.score = +item.score
				// 		return item
				// 	})
				// 	return data
				// } 
				// else {
				let leftList = [], rightList = []
				data.forEach((item, index) => {
					item.images = formatImg(item.images.split(',')[0])
					item.sales = +item.sales
					item.score = +item.score
					if (index%2 === 0) leftList.push(item) // 偶数第一排
					else rightList.push(item) // 奇数第二排
				})
				return [leftList, rightList]
				// }
			}
		}
	}

	/**
	 * 商品详情页面
	 */
	productDetail() {
		return {
			name: `client_product/detail`,
			isLogin: false,
			mustParam: ['id'],
			construction: function(data) {
				data.images = data.images.split(',').slice(0, 5).map(img => formatImg(img))
				data.sales = +data.sales
				data.score = +data.score
				data.pic = data.pic.split(',').map(img => formatImg(img))
				for (let key in data.format_pic) {
					data.format_pic[key] = formatImg(data.format_pic[key])
				}
				data.format_json = data.format_json.map(item => {
					item.list = item.list.map(format => {
						format.isClick = !!data.sku_list && data.sku_list.length !== 0 && data.sku_list.findIndex(sku => {
							return !!sku.key && sku.key[item.id] === format.id
						}) !== -1 && +format.select === 1
						return format
					})
					return item
				})
				return data
			}
		}
	}

	/**
	 * 添加到收藏
	 */
	addCollect() {
		return {
			name: `client_collect/add`,
			isLogin: false,
			mustParam: ['product_id'],
			method: 'post',
			construction: function(data) {
				return data
			}
		}
	}

	/**
	 * 取消收藏
	 */
	delCollect() {
		return {
			name: `client_collect/delete`,
			isLogin: false,
			method: 'post',
			mustParam: ['product_id'],
			construction: function(data) {
				return data
			}
		}
	}

	/**
	 * 收藏列表
	 */
	collectList(){
		return{
			name: `client_collect/list`,
			isLogin: false,
			mustParam: ['limit','offset'],
			construction: function(data) {		
				data.list = data.list.map(item => {
					item.images = formatImg(item.images.split(',')[0])
					item.isSelect = false;
					return item
				})
				return data
			}
		}
	}

	/**
	 * 收藏失效列表
	 */
	collectInvalidList(){
		return{
			name: `client_collect/invalid`,
			isLogin: false,
			mustParam: ['limit','offset'],
			construction: function(data) {
				data = data.map(item => {
					item.images = formatImg(item.images.split(',')[0])
					return item
				})
				return data
			}
		}
	}

	/**
	 * 获取指定商品对规格数据
	 */
	skuSelect() {
		return{
			name: `client_product/skuselect`,
			isLogin: false,
			mustParam: ['id'],
			construction: function(data) {
				data.images = data.images.split(',').map(img => formatImg(img))
				for (let key in data.format_pic) {
					data.format_pic[key] = formatImg(data.format_pic[key])
				}
				data.format_json = data.format_json.map(item => {
					item.list = item.list.map(format => {
						format.isClick = !!data.sku_list && data.sku_list.length !== 0 && data.sku_list.findIndex(sku => {
							return !!sku.key && sku.key[item.id] === format.id
						}) !== -1 && +format.select === 1
						return format
					})
					return item
				})
				return data
			}
		}
	}

	/**
	 * 获取猜你喜欢的数据
	 */
	getRecommend() {
		return{
			name: `client_product/recommend`,
			isLogin: false,
			construction: function(data) {
				let leftList = [], rightList = []
				data.forEach((item, index) => {
					item.images = formatImg(item.images.split(',')[0])
					if (index%2 === 0) leftList.push(item) // 偶数第一排
					else rightList.push(item) // 奇数第二排
				})
				return [leftList, rightList]
			}
		}
	}

	/**
	 * 获取二级列表页面的数据
	 */
	getSecondList() {
		return{
			name: `client_product/list`,
			isLogin: false,
			mustParam: ['id','limit','offset'],
			construction: function(data) {
				let leftList = [], rightList = []
				data.forEach((item, index) => {
					item.images = formatImg(item.images.split(',')[0])
					item.sales = +item.sales
					item.score = +item.score
					if (index%2 === 0) leftList.push(item) // 偶数第一排
					else rightList.push(item) // 奇数第二排
				})
				return [leftList, rightList]
			}
		}
	}
}

const server = new Server()

export default server

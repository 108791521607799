import { lazy } from 'react'

// 异步载入页面
export const Home = lazy(() => import('../pages/home/main'));
export const GoodsDetails = lazy(() => import('../pages/goodsDetails/main'));
export const IntegralMall = lazy(() => import('../pages/integralMall/main'));
export const Coupon = lazy(() => import('../pages/coupon/main'));
export const Order = lazy(() => import('../pages/order/main'));
export const OrderDetails = lazy(() => import('../pages/orderDetails/main'));
export const Logistics = lazy(() => import('../pages/logistics/main'));
export const OrderSubmit = lazy(() => import('../pages/orderSubmit/main'));
export const OrderSuccess = lazy(() => import('../pages/orderSuccess/main'));
export const PayCore = lazy(() => import('../pages/payCore/main'));
export const ShoppingCart = lazy(() => import('../pages/shoppingCart/main'));
export const Addr = lazy(() => import('../pages/Addr/main'));
export const EditAddr = lazy(() => import('../pages/EditAddr/main'));
export const MyCollect = lazy(() => import('../pages/myCollect/main'));
export const hotShop = lazy(() => import('../pages/hotShop/main'));
export const SearchResult = lazy(() => import('../pages/searchResult/main'));
export const SpecialsDetail = lazy(() => import('../pages/specialsDetail/main'));
export const Zhuanqu = lazy(() => import('../pages/zhuanqu/main'));

export const ApplyRefund = lazy(() => import('../pages/applyRefund/main'));
export const RefundChoose = lazy(() => import('../pages/refundChoose/main'));
export const NegotHistory = lazy(() => import('../pages/negotHistory/main'));
export const RefundDetails = lazy(() => import('../pages/refundDetails/main'));
export const RefundWuliu = lazy(() => import('../pages/refundWuliu/main'));

export const routerList = [
    {
        name: 'home',
        path: '/home',
        title: '商城',
        component: Home,
        keepAlive: true
    },
    {
        name: 'goodsDetails',
        path: '/goodsDetails/:id',
        title: '商品详情',
        component: GoodsDetails
    },
    {
        name: 'integralMall',
        path: '/integralMall',
        title: '积分商城',
        component: IntegralMall
    },
    {
        name: 'coupon',
        path: '/coupon',
        title: '优惠券',
        component: Coupon
    },
    {
        name: 'order',
        path: '/order',
        title: '订单列表',
        component: Order
    },
    {
        name: 'orderDetails',
        path: '/orderDetails/:orderNo',
        title: '订单详情',
        component: OrderDetails
    },
    {
        name: 'logistics',
        path: '/logistics/:id',
        title: '物流信息',
        component: Logistics
    },
    {
        name: 'orderSubmit',
        path: '/orderSubmit',
        title: '订单确认',
        component: OrderSubmit
    },
    {
        name: 'orderSuccess',
        path: '/orderSuccess',
        title: '提交成功',
        component: OrderSuccess
    },
    {
        name: 'payCore',
        path: '/payCore',
        title: '支付中心',
        component: PayCore
    },
    {
        name: 'shoppingCart',
        path: '/shoppingCart',
        title: '购物车',
        component: ShoppingCart
    },
    {
        name: 'addr',
        path: '/addr',
        title: '收货地址',
        component: Addr
    },
    {
        name: 'editAddr',
        path: '/editAddr',
        title: '编辑收货地址',
        component: EditAddr
    },
    {
        name: 'myCollect',
        path: '/myCollect',
        title: '我的收藏',
        component: MyCollect
    },
    {
        name: 'hotShop',
        path: '/hotShop',
        title: '',
        component: hotShop
    },
    {
        name: 'applyRefund',
        path: '/applyRefund',
        title: '申请退款',
        component: ApplyRefund
    },
    {
        name: 'refundChoose',
        path: '/refundChoose/:rId',
        title: '选择服务类型',
        component: RefundChoose
    },
    {
        name: 'negotHistory',
        path: '/negotHistory/:rId',
        title: '协商历史',
        component: NegotHistory
    },
    {
        name: 'refundDetails',
        path: '/refundDetails/:rId',
        title: '退款详情',
        component: RefundDetails
    },
    {
        name: 'refundWuliu',
        path: '/refundWuliu/:rId',
        title: '填写退货物流',
        component: RefundWuliu
    },
    {
        name: 'searchResult',
        path: '/searchResult/',
        title: '搜索结果',
        component: SearchResult
    },
    {
        name: 'specialsDetail',
        path: '/specialsDetail/:type',
        title: '专题',
        component: SpecialsDetail
    },
    {
        name: 'specialSearch',
        path: '/specialSearch/:title/:brand_id/:category_id/:key_word/:id',
        title: '专区',
        component: Zhuanqu
    }
]
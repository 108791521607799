import { qiniuUrl } from '../base.js'    // 基本信息文件
import user from './../vocational/userInfo'
// appLink
// 跳转页面
export function goRouter(url,title='') {
	if (!url) return
	if (url.indexOf('shop://') > -1) {
		// 商城跳转链接
		user.goRoute = {link: url.replace(/shop:\//, ''),title}
		// if (user.isAPP) window.location.href = url // app环境直接跳转
		// else {
		// 	// 跳转到指定页面， 暂无地址
		// 	console.log('非APP环境')
		// }
	} else window.location.href = url
}

// 跳转app打开
export function gotoApp() {
	window.location.href = "https://wwj.loovee.com/open/link?id=638"
	// window.location.href = "dingdingwawaji://com.loovee";
}
// imgUrl,
export function getQueryString(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}

export function getLocationQuery(search, name) {
	var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
	var r = search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}

// 格式化图片地址
export function formatImg (fileid, width, height) {
	let returnImg = null
	if(!fileid) returnImg = ''
	else if(fileid.indexOf('http://thirdwx.qlogo.cn') > -1) {
		returnImg = fileid.replace('http://thirdwx.qlogo.cn', 'https://wx.qlogo.cn')
	} else returnImg = fileid.indexOf('http') >= 0 ? fileid : qiniuUrl + fileid
	return !!width && !!height ? `${returnImg}${returnImg.indexOf('?') !== -1 ? '&' : '?' }imageMogr2/thumbnail/${width}x${height}` : returnImg
}

// 格式化数字  如： 7 ==>  07
export function formatNumber (n) {
    const str = n.toString()
	return str[1] ? str : `0${str}`
}


// 格式化倒计时
export function formatCount (date, serverTime, type = 'dd HH:mm:ss') {
	let timeDate = date
	// 补全时间戳
	if (date.toString().length < 13) {
		timeDate += '000'
	}
	let nowTime = serverTime
	if (serverTime.toString().length < 13) {
		nowTime += '000'
	}
	var endTime = new Date( Math.floor(timeDate)).getTime()
	var t = endTime - nowTime
	
	var day = Math.floor(t / 1000 / 60 / 60 / 24)
	var hour = Math.floor(t / 1000 / 60 / 60 % 24)
	var minute = Math.floor(t / 1000 / 60 % 60)
	var second = Math.floor(t / 1000 % 60)
	
	let format = type
	format = format.replace('dd', formatNumber(day))
	format = format.replace('HH', formatNumber(hour))
	format = format.replace('mm', formatNumber(minute))
	format = format.replace('ss', formatNumber(second))
	
	return format
}

// 获取格式化的时间
// type = YYYY-MM-dd HH:mm:ss (间隔随意写)
export function formatTime (date, type = 'YYYY-MM-dd HH:mm:ss') {
	let timeDate = date
	// 补全时间戳
	if (date.toString().length < 13) {
		timeDate += '000'
	}
    const time = new Date(+timeDate)
	
	let format = type
	
	const year = time.getFullYear()
	const month = time.getMonth() + 1
	const day = time.getDate()

	const hour = time.getHours()
	const minute = time.getMinutes()
	const second = time.getSeconds()
	
	format = format.replace('YYYY', formatNumber(year))
	format = format.replace('MM', formatNumber(month))
	format = format.replace('dd', formatNumber(day))
	format = format.replace('HH', formatNumber(hour))
	format = format.replace('mm', formatNumber(minute))
	format = format.replace('ss', formatNumber(second))

	return format
}

// 秒数转时分秒
export function formatSeconds (second) {
	var theTime = parseInt(second),   	// 秒
        middle  = 0,                	// 分
        hour    = 0;                	// 小时

    if (theTime > 60) {
        middle = parseInt(theTime/60);
        theTime = parseInt(theTime%60);
        if (middle > 60) {
            hour = parseInt(middle/60);
            middle = parseInt(middle%60);
        }
    }
    var result = "" + (parseInt(theTime) < 10 ? '0' + parseInt(theTime) : parseInt(theTime)) + "秒";
    if (middle > 0) {
        result = "" + (parseInt(middle) < 10 ? '0' + parseInt(middle) : parseInt(middle)) + "分" + result;
    }
    if (hour> 0) {
        result = "" + (parseInt(hour) < 10 ? '0' + parseInt(hour) : parseInt(hour)) + "小时" + result;
    }

    return result;
}

// 获取UUID
export function uuid () {
	let s = [];
	let hexDigits = "0123456789abcdef";
	for (let i = 0; i < 36; i++) {
		s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
	}
	s[14] = "4";
	s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
	s[8] = s[13] = s[18] = s[23] = "-";

	let uuid = s.join("");
	return uuid;
}

// 生成随机的6位数
export function requestNum () {
	return Math.random().toString(36).substr(2).slice(2, 8)
}

// 根据对象的属性给数组排序
export function attrSort (arr, attr) {
	return arr.sort(((attr,rev) => {
        // 第二个参数没有传递 默认升序排列
        if(rev ===  undefined){
            rev = 1;
        }else{
            rev = (rev) ? 1 : -1;
        }
        
        return function(a,b){
            a = a[attr];
            b = b[attr];
            if(a < b){
                return rev * -1;
            }
            if(a > b){
                return rev * 1;
            }
            return 0;
        }
    })(attr, false)) // (这里我默认传了倒序)
}


//生成从minNum到maxNum的随机数
export function randomNum(minNum,maxNum){
	let result = ''
    switch(arguments.length){ 
        case 1: 
		result = parseInt(Math.random()*minNum+1,10); 
        break; 
        case 2: 
		result = parseInt(Math.random()*(maxNum-minNum+1)+minNum,10); 
        break; 
		default: 
		result = 0; 
		break; 
	} 
	return result
}


// 吧手机号码中间4位转化为  ****
export function formatPhone(phone) {
	let data = phone.split('')
	// console.log(data)
	data[3] = '*'
	data[4] = '*'
	data[5] = '*'
	data[6] = '*'
	return data.join('')
}

// 设置操作间隔
let operationLock = []			// 操作间隔函数
let operationInter = {}			// 操作间隔定时器
export function operationInterval(type, fn, time = 500) {
	if (!operationLock.includes(type)) {
		operationLock.push(type)
		fn()
		operationInter[type] = setTimeout(() => {
			const index = operationLock.findIndex(item => type === item)
			operationLock.splice(index, 1)
			clearTimeout(operationInter[type]);
			operationInter[type] = null
			delete operationInter[type]
		}, time)// 操作的时间间隔
	} else {
		// 存在同样的事件，直接跳过
	}
}


// 开启定时器
let timerAggregate = {}
export function openTimer(name, fn, time) {
	clearTimeout(timerAggregate[name])
	timerAggregate[name] = null
	timerAggregate[name] = setTimeout(() => {
		fn()
		clearTimeout(timerAggregate[name])
		timerAggregate[name] = null
		delete timerAggregate[name]
	}, time)
}
// 获取定时器
export function getTimer(name) {
	return timerAggregate[name] || null
}
// 手动清理定时器
export function clearTimer(name) {
	if (!!timerAggregate[name]) {
		clearTimeout(timerAggregate[name])
		timerAggregate[name] = null
		delete timerAggregate[name]
	}
}

// 复制的方法
export function copy(text, callback){ // text: 要复制的内容， callback: 回调
    var tag = document.createElement('input');
    tag.setAttribute('id', 'cp_hgz_input');
    tag.setAttribute('style', 'position: fixed; top: 0; left: 0; z-index: -10');
    tag.value = text;
    document.getElementsByTagName('body')[0].appendChild(tag);
    document.getElementById('cp_hgz_input').select();
    document.execCommand('copy');
    document.getElementById('cp_hgz_input').remove();
    if(callback) {callback(text)}
}

// 判断对象是否完全相等
export function vequal(obj1, obj2) {
	var props1 = Object.getOwnPropertyNames(obj1);
	var props2 = Object.getOwnPropertyNames(obj2);
	if (props1.length !== props2.length) {
		return false;
	}
	for (var i = 0,max = props1.length; i < max; i++) {
		var propName = props1[i];
		if (obj1[propName] !== obj2[propName]) {
			return false;
		}
	}
	return true;
}

// 更改标题
export function changeTitle(title) {
	document.title = title;
    const mobile = navigator.userAgent.toLowerCase();
    // 针对微信
    if (/iphone|ipad|ipod/.test(mobile)) {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.setAttribute('src', '');
        const iframeCallback = function () {
            setTimeout(function () {
                iframe.removeEventListener('load', iframeCallback);
                document.body.removeChild(iframe);
            }, 0);
        };
        iframe.addEventListener('load', iframeCallback);
        document.body.appendChild(iframe);
    }
}

// 节流
export function throttle(fn,delay = 3000) {
	let lastTime = 0;
    return function(e){
        const nowTime = new Date().getTime();
        if(nowTime - lastTime > delay){
            fn.apply(this, arguments);
            lastTime = nowTime;
        }
    }
}

// 输入当前时间戳和目标时间戳 转为几天几时几分几秒
export function formatDateCount(now, time) {
	// 获取现在的时间
	var date = now;
	// var until = new Date('2020-10-01 00:00:00');
	var until = time
	// 补全时间戳
	if (until.toString().length < 13) {
		until += '000'
	}

	// 计算时会发生隐式转换，调用valueOf()方法，转化成时间戳的形式
	var days = (until - date)/1000/3600/24; 

	var day = Math.floor(days);
	var hours = (days - day)*24;
	var hour = Math.floor(hours);
	var minutes = (hours - hour)*60;
	var minute = Math.floor(minutes);
	var seconds = (minutes - minute)*60;
	var second = Math.floor(seconds);

	var result = `还剩${day}天${hour}时${minute}分${second}秒`;
	return result
}